.projects-container {
    display: flex;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}


.content-container>*>.language-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    justify-content: center;
    overflow-y: auto;
    overflow-x: hidden;
}

.lang-tabs {
    display: flex;
    justify-content: space-around;
    margin: 0 15px;
}

.lang-tabs>div {
    border: 2px double #D9D9D9;
    border-bottom: 0px;
    padding: 5px 17px;
    font-size: 25px;
    color: #D9D9D9;
    margin: 0 3px;
    width: 20%;
}

.lang-content {
    overflow-y: auto;
    overflow-x: hidden;
    border: 5px double #d9d9d9;
    height: calc(100% - 60px);
    display: flex;
    flex-direction: column;
    align-items: center;
}


.lang-tabs>.active-tab {
    border: 2px solid #3BFF31;
    background-color: #3BFF31;
    color: #1e1e1e;
    border-bottom: 0;
}



@media only screen and (max-width: 768px) {
    .lang-tabs>div {
        padding: 4px;
        font-size: 12px;
        color: #D9D9D9;
        margin: 0 3px;
        width: 20%;
    }

    .lang-content {
        margin: 0 10px;
        padding: 0 5px
    }
}