.sidebar {
    display: flex;
    flex-direction: column; 
    align-items: center;
    box-sizing: border-box; 
    position: relative; 
}


.name-plate{
    font-size: 2em;
    text-align: center;
    padding:10px;
    margin:0 4px;
}

.navbar_ul{
    width: 100%;
    padding: 0px;
    margin-top:0px ;

}

.navbar_ul>li{
    flex:1;
    display: flex; 
    justify-content: center; 
    align-items: center; 
    list-style-type: none;
    border-top: 4px solid #2b2d2e;
    text-align: center;
    transition: box-shadow 0.3s ease, transform 0.3s ease
}

.navbar_ul>li>a{
    display: flex; 
    align-items: center; 
    color:#40AE3B;
    width: 100%;
    margin: 2px auto;
}



.navbar_ul>li>*.active {
    background-color: #3BFF31;
    color: #2e2e2e;
    height: 100%;
    box-sizing:border-box;
}


.navbar_ul> li.active:hover {
    transform: scale(1.05);
}


.navbar_ul> li:hover {
    box-shadow: 0 0 20px #40AE3B;
    background-color: #1e1e1e; 
    transform: scale(1.03); 
    border-right-color:3px solid #40AE3B;
}

.sidebar-footer {
    margin-top: auto;
    padding: 10px;
    text-align: center;
    font-size: 0.9em;
    display: flex;
    flex-direction: column;
}
.sidebar-footer-link{
    color: #6e6e6e;
}

.sidebar-footer-link:hover{
    text-shadow: 0 0 10px white, 0 0 20px white, 0 0 30px white;
    color: white;
}

@media only screen and (min-width: 769px){
    .sidebar{
        border-right: 7px solid #2b2d2e;
    }
    .navbar_ul{
    width: 250px; 
    min-height: 30%;
    display: flex;
    flex-direction: column;
    }
    .navbar_ul>li>a{
    font-size: 30px;
    justify-content:flex-start; 
    }

    .navbar_ul>li>a>img{
        margin:0 10px;
        padding: 0 10px;
    }
   }


@media only screen and (max-width: 768px) {
    .sidebar {
        position: sticky;
      width: 100%;
      display: flex;
      border: 7px solid #2b2d2e;
    }

    .expertise-container, .projects-container, .experience-container, .home-container, .contact-container {
      flex-direction: column-reverse;
      flex: 1;
    }

    

    .navbar_ul>li>a{
        justify-content: center;
        margin: none;
    }
  
    .sidebar-footer{
        display: none;
    }
  
    .navbar_ul {
      display: flex;
      flex-direction: row;
      min-height: 10%;
      width: 100%;
      justify-content: space-evenly;
      padding: 0;
      margin: 0; 
    }

    .sidebar-image{
    margin:0px 2px
    } 
  
    .sidebar-text {
      display: none;
    }
  }
