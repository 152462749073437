.big-screen {
	border: 3px solid #3bff31;
	background-color: #1e1e1e;
	height: 100%;
	display: flex;
	flex-direction: column;
	overflow-y: auto;
}

.topbar {
	display: flex;
	justify-content: space-between;
	background-color: #3bff31;
	color: #2e2e2e;
	font-size: 24px;
	width: 100%;
}

.header {
	display: flex;
	justify-content: space-around;
	margin: 10px 0px;
}


.scrollable-content {
	flex: 1;
	overflow-y: auto;
	padding: 10px;
	scrollbar-color: #3bff31 #2d2d2d;
}

.big-screen::-webkit-scrollbar {
	width: 100px;
}

.big-screen::-webkit-scrollbar-track {
	background-color: #2e2e2e;
}

.big-screen::-webkit-scrollbar-thumb {
	background-color: #3bff31;
}

.big-screen::-webkit-scrollbar-thumb:hover {
	background-color: #2bff2f;
}

#exit {
	width: 20px;
	height: 20px;
}

.page_title {
	align-self: center;
	padding: 10px;
	max-width: 180px;
	border: 2px solid #3bff31;
	margin: 10px;
}



.spacers{
	font-size: 3em;
}

.botbar{
	display: flex;
	background-color: #3bff31;
	color: #2e2e2e;
	font-size: 13px;
	width: 100%;
}





@media only screen and (min-width: 769px) {
	.page_title {
		font-size: 30px;
	}
}
@media only screen and (max-width: 768px) {
	.page_title {
		font-size: 16px;
	}
	.topbar {
		font-size: 18px;
	}

}