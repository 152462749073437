.App {
  text-align: center;
  background-color: #1E1E1E;
  height: 100dvh;
  width: 100dvw;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #3BFF31;
}

