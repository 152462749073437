@import url('https://fonts.googleapis.com/css2?family=VT323&display=swap');


/* GLOBAL CSS PROPS */
html, body {
  margin: 0;
  padding: 0;
  height: 100dvh; 
  width: 100dvw;
  font-family: 'VT323', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.content-container {
  background-color: black;
  flex: 2;
  padding: 20px;
  box-sizing: border-box;
  overflow-y: hidden;
}

.box-link{
  background-color: #3BFF31;
  color: #1e1e1e;
  font-family: 'VT323', monospace;
  font-size: 20px;
  margin:3px 6px 10px;
  padding: 8px 20px;
  border: 3px solid 1e1e1e;
  transition: text-shadow 0.3s ease, color 0.3s ease;
}

.box-link:hover {
  text-shadow: 0 0 10px white, 0 0 20px white, 0 0 30px white;
  color: white;
}

.box-link:active {
  background-color: #40AE3B;
}

p{
  font-size: 20px;
}