.contact-container {
    display: flex;
    width: 100%;
    height: 100%;
    box-sizing: border-box;
}


#contact-big-parent>* {
    align-items: center;
    justify-content: space-around;
}

#contact-big-parent>*>.scrollable-content {
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
}

.email>a>pre {
    font-size: 8px;
}

.linkedin>a>pre {
    font-size: 7px;
    padding: 5px 10px
}

.email,
.linkedin {
    font-size: 18px;
    border: 4px solid #3BFF31;
    margin: 6px;
    transition: text-shadow 0.3s ease, color 0.3s ease;
    min-width: 230px;
    padding: 2px 10px;
background-color: #1c2335;
box-shadow: 10px 10px #000000;
}

.email:hover,
.linkedin:hover {
    text-shadow: 0 0 10px white, 0 0 20px white, 0 0 30px white;
    color: white;
    transform: scale(1.04);
}

a {
    color: #3BFF31;
    text-decoration: none;
    font-size: 15px;
    margin:10px;
}

button {
    background-color: #3BFF31;
    color: #1e1e1e;
    font-family: 'VT323', monospace;
    font-size: 20px;
    margin: 3px 6px;
    transition: text-shadow 0.3s ease, color 0.3s ease;
}

button:hover {
    text-shadow: 0 0 10px white, 0 0 20px white, 0 0 30px white;
    color: white;
}

button:active {
    background-color: #40AE3B;
}
