.welcome-container {
  text-align: center;
  width: 100vw;
  height: 100vh; 
  position: relative; 
}

.ascii-canvas {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  pointer-events: none; 
  opacity: 1;
  transition: opacity 1s ease-in-out; 
}

.canvas-hidden {
  opacity: 0; 
}
.ascii-art-welcome{
  font-size: 20px;
}

.ascii-art-welcome, .enter, h2, h3 , #typewriter{
  position: relative;
  z-index: 1;
  opacity: 0; 
  transition: opacity 1s ease-in-out; 
}

.animation-complete .ascii-art-welcome,
.animation-complete .enter,
.animation-complete h2,
.animation-complete h3,  
.animation-complete #typewriter{
  opacity: 1;
}

#typewriter {
  overflow: hidden; 
  border-right: .10em solid #3BFF31; 
  white-space: nowrap; 
  margin: 0 auto; 
  letter-spacing: .01em; 
  max-width: 15em;
  animation: 
    typing 11s steps(50, end), 
    blink-caret 1s step-end infinite;
}


#typewriter.visible {
  opacity: 1;
}

h1 {
  font-weight: normal;
  font-size: 40px;
}

.enter {
  animation: breathing 3s ease-in-out infinite;
  transition: text-shadow 1s ease-in-out, color 1s ease-in-out;
  padding: 6px 15px;
  margin:20px auto 0;
  cursor: pointer;
  background-color: black;
  max-width: 130px;
  border: 2px dashed #3BFF31;
  border-radius: 10px;
}

.enter:hover {
  color: #fff; 
  border-color: #fff;
  transition: box-shadow 1s ease-in-out, color 1s ease-in-out;
  text-shadow: 0 0 10px rgba(255, 255, 255, 0.8), 
               0 0 20px rgba(255, 255, 255, 0.6), 
               0 0 30px rgba(255, 255, 255, 0.4); 
}

h2 {
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-size: 45px;
  font-weight: normal;
  color: #40AE3B;
  margin: 1px;
}

h3 {
  position: absolute;
  bottom: 15px;
  left: 10px;
  font-size: 30px;
  font-weight: normal;
  color: #40AE3B;
  margin: 1px;
}

/* Keyframes for breathing effect */
@keyframes breathing {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}

/* Keyframes for typewriter effect */
@keyframes typing {
  from { width: 0 }
  to { width: 100% }
}

@keyframes blink-caret {
  from, to { border-color: transparent }
  50% { border-color: #3BFF31; }
}


@media only screen and (max-width: 768px) {
#typewriter {
  overflow: hidden; 
  border-right: .10em solid #3BFF31; 
  white-space: nowrap; 
  margin: 0 auto; 
  letter-spacing: .01em; 
  max-width: 5em;
  animation: 
    typing 11s steps(50, end), 
    blink-caret 1s step-end infinite;
}
  h2 {
    position:relative;
    font-size: 30px;
    bottom:auto;
right: auto;
    font-weight: normal;
    color: #40AE3B;
    margin: 10px;
  }
  
  h3 {
    position: relative;
    bottom:0px;
    left: auto;
    font-size: 20px;
    font-weight: normal;
    color: #40AE3B;
    margin: 10px;
  }

  .ascii-art-welcome{
    font-size: 8px;
  }
  
  .welcome-container{
    display: flex;
    justify-content: space-around;
    flex-direction: column;
  }
}