.small-container{
    display: flex;
    flex-direction: column;
    align-items: center;
}

.small-screen {
	border: 3px solid #40AE3B;
	background-color: rgba(30, 30, 30, 0.8);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 10px 10px 0;
}


.topbar-small {
	display: flex;
	background-color: #40AE3B;
	align-items: center;
	color: #2e2e2e;
	font-size: 24px;
	width: 100%;
}

.small_title {
	text-decoration: underline;
}

.small_sub_title {
	color: #D9D9D9;
	text-align: left;
	text-wrap: wrap;
	margin-top: 10px;
}

video{
	border: 3px solid #D9D9D9;
	width:80%
}

.small_info{
margin-top: 10px;
}

.small_text {
	color: #40ae3b;
	text-align: start;
	text-wrap: wrap;
}
.small_ascii {
	font-size: 8px;
	margin: 10px 20px 10px 0;
}	


.small_img {
	width: 80%;
	border: 2px solid #D9D9D9;
	padding: 2px;
	margin: 12px
}

.small_vid{
	margin-bottom:20px;
}

@media only screen and (max-width: 768px) {
	.small-screen {
		margin: 20px 5px 0;
		max-width:80%
	}

	
.topbar-small {
	font-size: 17px;
}
	
.small_title {
	 font-size: 18px;
	margin: 4px 4px 0;
	text-decoration: underline;
}


.small_img {
	width: 80%;
}

.small_ascii {
	font-size: 6px;
	padding: 15px;
	margin:auto;
}

.small_text{
	margin: 2px;
}
}


@media only screen and (min-width: 769px) {
.small-screen {
	margin: 20px 0 0;
	min-width:450px;
    max-width: 550px;
}
.small_title {
	margin: 10px 5px 0;
	font-size: 22px;
}

.small_sub_title {
padding:  0px;
list-style: none;
margin: 5px 10px 0;
font-size: 20px;
}


.small_text {
padding: 5px 10px 0;
margin:0 20px;
font-size: 18px;
}

.small_info{
font-size: 18px;	
}

.small_img {
	width: 70%;
}
}
