.expertise-container {
    display: flex; 
    width: 100%; 
    height: 100%; 
    box-sizing: border-box; 
}

.UML {
    width: 100%;
    height: 100%;
    background-image: url('../content/Expertise_UML.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
}

.expertise-container>.content-container>div>.scrollable-content{
    overflow-y:hidden;
}