.popup-container {
    position: fixed;
    top: 20px;
    right: 20px;
    background-color: #282828;
    padding: 10px 20px;
    border-radius: 5px;
    color: #fff;
    z-index: 1000;
    display: flex;
    align-items: center;
}

.popup-message {
    font-size: 16px;
}
